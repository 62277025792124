import { DOCUMENT } from '@angular/common';
import { Injectable, afterNextRender, inject } from '@angular/core';
import Plausible from 'plausible-tracker';
@Injectable({
  providedIn: 'root',
})
export class PlausibleService {
  document = inject(DOCUMENT);
  website = `//${this.document.location.host}/plausible`;
  plausible = Plausible({
    domain: this.document.location.host,
    apiHost: this.document.location.host === 'w.awread.vn' ? 'https://awread.vn/plausible' : '/plausible',
  });
  enableAutoPageviews = () => { };
  trackEvent = (eventName: any, options?: any, eventData?: any) => { };
  trackPageview = (eventData?: any, options?: any) => { };
  constructor() {
  }

  start(domain = 'awread.vn') {
    console.log('plausible started: ', domain, this.website)
    afterNextRender(() => {
      this.enableAutoPageviews = this.plausible.enableAutoPageviews;
      this.trackEvent = this.plausible.trackEvent;
      this.trackPageview = this.plausible.trackPageview;
      this.enableAutoPageviews();
    })
  }
}

